import { FC } from 'react';
import { Box } from '@mantine/core';

import LogoImg from '@/assets/inline-svg/Logo.svg';

import { useStyles } from './styles';

const Logo: FC = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <LogoImg />
    </Box>
  );
};

export default Logo;
