import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  root: {
    background: 'none',
    height: 'auto',
    maxHeight: 'none',

    [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
      textAlign: 'center',
    },
  },

  row: {
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: 20,

    [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'initial',
      gap: 60,
      marginTop: 70,
    },
    [`@media (max-width: ${theme.breakpoints.sm - 1}px)`]: {
      marginTop: 65,
    },
    [`@media (max-width: ${theme.breakpoints.xs - 1}px)`]: {
      gap: 31,
      marginTop: 60,
    },
  },

  contacts: {
    flexGrow: 1,
    maxWidth: 375,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 15,

    [`@media (max-width: ${theme.breakpoints.lg - 1}px)`]: {
      maxWidth: 310,
    },
    [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
      alignItems: 'center',
    },
    [`@media (max-width: ${theme.breakpoints.xs - 1}px)`]: {
      gap: 11,
    },
  },

  contact: {
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: 38,
    lineHeight: 1,
    whiteSpace: 'nowrap',
    color: theme.black,

    [`@media (max-width: ${theme.breakpoints.lg - 1}px)`]: {
      fontSize: 36,
    },
    [`@media (max-width: ${theme.breakpoints.xs - 1}px)`]: {
      fontWeight: 400,
    },
  },

  contactHighlighted: {
    backgroundColor: '#EDBAFE',
    padding: '7px 16px 15px',

    svg: {
      marginRight: 20,
      marginTop: 4,
      marginBottom: -4,
    },
  },

  address: {
    fontSize: 18,
    maxWidth: 280,
  },

  addressTitle: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: 1.5,
    marginBottom: 28,

    [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
      marginBottom: 23,
    },
    [`@media (max-width: ${theme.breakpoints.xs - 1}px)`]: {
      fontSize: 18,
      lineHeight: 1.93,
      marginBottom: 26,
    },
  },

  addressGroup: {
    gap: 29,

    [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
      justifyContent: 'center',
      gap: 14,
    },
  },

  addressInfo: {
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },

  addressText: {
    lineHeight: 1.5,
    marginTop: 7,
  },

  copy: {
    fontSize: 18,
    marginTop: 23,

    [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
      marginTop: 35,
    },
    [`@media (max-width: ${theme.breakpoints.xs - 1}px)`]: {
      marginTop: 16,
    },
  },
}));
