import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  root: {
    marginLeft: 96,
  },

  item: {
    '&:active': {
      ...theme.activeStyles,
    },
  },
}));
