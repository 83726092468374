import type { GatsbyBrowser } from 'gatsby';

import { WrapRootElement } from '@/utils/WrapRootElement';
import { WrapPageElement } from '@/utils/WrapPageElement';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return <WrapRootElement>{element}</WrapRootElement>;
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  return <WrapPageElement props={props}>{element}</WrapPageElement>;
};
