import React, { FC, ReactNode } from 'react';
import { MantineProvider } from '@mantine/core';

import '@fontsource/mulish';

import { appTheme } from '@/constants/mantine/appTheme';

interface IWrapRootElement {
  children: ReactNode;
}

export const WrapRootElement: FC<IWrapRootElement> = ({ children }) => {
  return (
    <React.StrictMode>
      <MantineProvider withNormalizeCSS withGlobalStyles theme={appTheme}>
        {children}
      </MantineProvider>
    </React.StrictMode>
  );
};
