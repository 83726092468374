import { FC } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useMediaQuery } from '@mantine/hooks';
import { Button, Container, Header } from '@mantine/core';
import { BREAKPOINTS } from '@/constants/mantine/breakpoints';
import { appSections } from '@/constants/appSections';

import Logo from './Logo/Logo';
import HeaderNav from './HeaderNav/HeaderNav';

import { useStyles } from './styles';

const LayoutHeader: FC = () => {
  const largerLg = useMediaQuery(`(min-width: ${BREAKPOINTS.lg}px)`);
  const largerMd = useMediaQuery(`(min-width: ${BREAKPOINTS.md}px)`);

  const { classes } = useStyles();

  return (
    <Header className={classes.root} withBorder={false} height={134}>
      <Container className={classes.content}>
        <Logo />

        {largerLg && <HeaderNav />}
        {largerMd && (
          <Button variant="default" component={AnchorLink} to={`/#${appSections.contacts.id}`}>
            Связаться с нами
          </Button>
        )}
      </Container>
    </Header>
  );
};

export default LayoutHeader;
