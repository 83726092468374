export const appSections = {
  advantages: {
    id: 'advantages',
    title: 'Преимущества',
  },
  services: {
    id: 'services',
    title: 'Услуги',
  },
  cases: {
    id: 'cases',
    title: 'Кейсы',
  },
  team: {
    id: 'team',
    title: 'Команда',
  },
  contacts: {
    id: 'contacts',
    title: 'Контакты',
  },
};
