import { FC, ReactNode } from 'react';
import { Box, Container, DefaultProps, Title } from '@mantine/core';

import { useStyles } from './styles';

interface ISectionTitle extends DefaultProps {
  children: ReactNode;
}

interface ISection extends DefaultProps {
  id: string;
  children: ReactNode;
  containerProps?: DefaultProps;
}

interface ISectionSubComponents {
  Title: FC<ISectionTitle>;
}

const SectionTitle: FC<ISectionTitle> = ({ children, className, ...props }) => {
  const { classes, cx } = useStyles();

  return (
    <Title className={cx(className, classes.title)} order={2} {...props}>
      {children}
    </Title>
  );
};

const Section: FC<ISection> & ISectionSubComponents = ({
  id,
  children,
  className,
  bg,
  containerProps,
  ...props
}) => {
  const { classes, cx } = useStyles();

  return (
    <Box id={id} className={cx(className, classes.root)} bg={bg} {...props}>
      <Container {...containerProps}>{children}</Container>
    </Box>
  );
};

Section.Title = SectionTitle;

export default Section;
