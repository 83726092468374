import React, { FC, ReactNode } from 'react';
import { PageProps } from 'gatsby';

import Layout from '@/ui/Layout/Layout';

interface IWrapPageElement {
  children: ReactNode;
  props: PageProps;
}

export const WrapPageElement: FC<IWrapPageElement> = ({ children, props }) => {
  return <Layout {...props}>{children}</Layout>;
};
