import { FC } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Group, UnstyledButton } from '@mantine/core';
import { appSections } from '@/constants/appSections';

import { useStyles } from './styles';

const HeaderNav: FC = () => {
  const { classes } = useStyles();

  return (
    <Group className={classes.root} noWrap spacing={37}>
      {Object.values(appSections).map((item) => (
        <UnstyledButton
          key={item.id}
          className={classes.item}
          component={AnchorLink}
          to={`/#${item.id}`}
        >
          {item.title}
        </UnstyledButton>
      ))}
    </Group>
  );
};

export default HeaderNav;
