import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  root: {
    background: 'none',
    height: 134,
    maxHeight: 'none',
    paddingTop: 41,
    paddingBottom: 41,

    [`@media (max-width: ${theme.breakpoints.lg - 1}px)`]: {
      height: 144,
      paddingTop: 46,
      paddingBottom: 46,
    },
    [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
      height: 124,
      paddingTop: 31,
      paddingBottom: 31,
    },
    [`@media (max-width: ${theme.breakpoints.sm - 1}px)`]: {
      height: 106,
      paddingTop: 22,
      paddingBottom: 22,
    },
    [`@media (max-width: ${theme.breakpoints.xs - 1}px)`]: {
      height: 94,
    },
  },

  content: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 24,

    [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
      justifyContent: 'center',
    },
  },
}));
