import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  body: {
    flex: '1 1 0%',
  },
}));
