import { createStyles, keyframes } from '@mantine/core';

const rotate = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(360deg)' },
});

export const useStyles = createStyles((theme) => ({
  root: {
    alignSelf: 'flex-start',
    display: 'flex',

    '&:hover': {
      '.animate': {
        transformBox: 'fill-box',
        transformOrigin: 'center',
        animation: `${rotate} 1s linear infinite`,
      },
    },

    svg: {
      width: 'auto',

      [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
        height: 62,
      },
      [`@media (max-width: ${theme.breakpoints.xs - 1}px)`]: {
        height: 50,
      },
    },
  },
}));
